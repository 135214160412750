import React, { useState, useEffect, lazy, Suspense } from "react"

const Header = lazy(() => import("./components/header/Header"))
const Home = lazy(() => import("./components/home/Home"))
const Features = lazy(() => import("./components/features/Features"))
const Menu = lazy(() => import("./components/menu/Menu"))
const Gallery = lazy(() => import("./components/gallery/Gallery"))
const Offer = lazy(() => import("./components/offer/Offer"))
const Testimonials = lazy(() =>
  import("./components/testimonials/Testimonials")
)
const Footer = lazy(() => import("./components/footer/Footer"))
const FooterMedium = lazy(() => import("./components/footer/FooterMedium"))

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <Header />
      <Home />
      <Suspense fallback={<div>loading...</div>}>
        <Offer />
      </Suspense>
      <Suspense fallback={<div>loading...</div>}>
        <Features />
        <Menu />
        <Suspense fallback={<div>loading...</div>}>
          <Gallery />
        </Suspense>
        <Suspense fallback={<div>loading...</div>}>
          <Testimonials />
        </Suspense>
        {windowWidth > 768 ? <Footer /> : <FooterMedium />}
      </Suspense>
    </>
  )
}
export default App
